<template>
  <div class="home">
    <Menu></Menu>
    <Banner></Banner>
    <Carousel></Carousel>
    <AboutUs></AboutUs>
    <FAQS></FAQS>
    <Footer></Footer>
  <!--  <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Menu from '@/components/NavBar.vue'
import Banner from '@/components/Banner.vue'
import Carousel  from "@/components/Carousel.vue"
import AboutUs from "@/components/Nostros.vue"
import FAQS from '@/components/FAQS.vue'
import Footer from '@/components/Footer.vue'
import { carouselItems, currentIndex, changeSlide } from '@/components/Carousel.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    Menu,
    Banner,
    Carousel,
    AboutUs,
    FAQS,
    Footer
  },setup() {
    return {
      carouselItems,
      currentIndex,
      changeSlide
    };
  }
}
</script>
