<template>
  <router-view/>
</template>

<script>
import axios from 'axios';

export default {
  created() {
    axios.defaults.baseURL = 'https://nicosorteos-8b36160039d0.herokuapp.com'; // Ajusta la URL de tu servidor

    // Configura un interceptor para agregar el token de autenticación en el encabezado de autorización de todas las solicitudes
    axios.interceptors.request.use(config => {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    });
  }
}
</script>


<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
