<template>
  <div class="faq-section" id="faqs">
    <h2>Preguntas Frecuentes</h2>
    <div class="faq-list">
      <div class="faq-item" v-for="(faq, index) in faqData" :key="index" @click="toggleFAQ(index)"
        :class="{ active: activeIndex === index }">
        <div class="faq-question">
          {{ faq.question }}
          <span class="faq-toggle">{{ activeIndex === index ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" v-if="activeIndex === index">
          <p>{{ faq.answer }}</p>
        </div>
      </div>
    </div>

    <div class="contact-invitation">
      <h3>¿Aún con dudas?</h3>
      <p>Acércate con nosotros vía WhatsApp o por nuestra página de Facebook, será un gusto atenderte y contestar tus
        dudas.</p>
    </div>

    <div class="contact-buttons">
      <a href="https://wa.me/4445454450" class="whatsapp-button" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-whatsapp" style="margin-right: 15px;"></i> Contáctanos por WhatsApp
      </a>
      <a href="https://www.facebook.com/profile.php?id=61555499965336" class="facebook-button" target="_blank"
        rel="noopener noreferrer">
        <i class="fab fa-facebook" style="margin-right: 15px;"></i> Contáctanos por Facebook
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: null,
      faqData: [
        {
          question: '¿Cómo puedo comprar un boleto?',
          answer: 'En Nicos Sorteos Potosinos, el proceso de participación es simple y emocionante. Para empezar, visita nuestra plataforma y navega hasta la sección de sorteos, donde encontrarás una variedad de opciones para elegir. Explora los detalles de cada sorteo y elige el que más te guste. Después, selecciona tus números de la suerte y completa el formulario de participación con tus datos personales. Una vez enviado, serás redirigido a WhatsApp, donde nuestro equipo te guiará en el proceso de compra de tus boletos. ¡Con Nicos Sorteos Potosinos, la oportunidad de ganar premios increíbles está a solo unos clics de distancia!'
        },
        {
          question: '¿Cuándo se anuncian los ganadores?',
          answer: 'En Nicos Sorteos Potosinos, la emoción continúa incluso después de que termina el sorteo. Cada uno de nuestros sorteos se basa en los números ganadores de la Lotería Nacional, lo que garantiza un proceso transparente y justo. Una vez que el sorteo ha concluido, los resultados se publicarán en nuestra página web, reflejando los números ganadores seleccionados por la Lotería Nacional. Si eres afortunado y tu combinación coincide con los números ganadores, ¡nuestro equipo se pondrá en contacto contigo directamente! Mantente atento a tu correo electrónico o teléfono, ya que te informaremos sobre tu premio y los pasos a seguir para reclamarlo. En Nicos Sorteos Potosinos, no solo ofrecemos oportunidades de ganar emocionantes premios, sino que también nos aseguramos de brindarte una experiencia confiable y transparente en cada paso del camino.'
        },
        {
          question: '¿Qué métodos de pago aceptan?',
          answer: ' Una vez que hayas iniciado el proceso de apartado de boletos a través de nuestra plataforma, nuestro equipo se pondrá en contacto contigo para recopilar toda la información necesaria para completar tu compra. El pago de los boletos se puede realizar mediante transferencia bancaria o depósitos en tiendas de conveniencia. Para garantizar una experiencia sin contratiempos, te proporcionaremos los detalles de la cuenta bancaria y la información necesaria para realizar el depósito a través de WhatsApp. Estamos comprometidos a brindarte un servicio de calidad y a facilitarte el proceso de compra de tus boletos en Nicos Sorteos Potosinos.'
        },
        {
          question: '¿Cómo se hacen los envíos y a qué países hacen envíos?',
          answer: 'En Nicos Sorteos Potosinos, nos aseguramos de que recibir tus premios sea una experiencia conveniente y segura. Si eres afortunado y te encuentras en San Luis Potosí, nos pondremos de acuerdo contigo para hacer la entrega personalmente, garantizando así que recibas tu premio de manera rápida y directa. En el caso de que residas en otra parte del país, nos comunicaremos contigo para coordinar el envío correspondiente a tu ubicación. Queremos asegurarnos de que disfrutes de tu premio sin complicaciones, por lo que nos encargaremos de todos los detalles logísticos para que tu premio llegue a tus manos de manera segura y oportuna. Es importante destacar que, por el momento, solo es posible participar en nuestros sorteos si te encuentras dentro de México. ¡Gracias por ser parte de la familia de Nicos Sorteos Potosinos!'
        }
      ]
    };
  },
  methods: {
    toggleFAQ(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    }
  }
};
</script>

<style scoped>
.faq-section {
  max-width: 90%;
  margin: 0 auto;
  padding: 30px 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  /* Soft shadow for depth */
  border-radius: 10px;
  /* Rounded corners for modern look */
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  /* Primary color */
  margin-bottom: 30px;
}

.faq-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #C13349;
  /* Usa el color principal para destacar el título */
  margin-bottom: 1rem;
  /* Espacio después del título */
  position: relative;
}

.faq-section h2::after {
  content: '';
  width: 50px;
  /* Ancho de la línea decorativa */
  height: 4px;
  /* Altura de la línea decorativa */
  background-color: #C13349;
  /* Color de la línea */
  display: block;
  /* Hace que la pseudo-elemento sea como un bloque */
  margin: 10px auto 30px;
  /* Centra la línea y añade espacio antes y después */
}


.faq-list {
  list-style: none;
  padding: 0;
}

.faq-item {
  background: #f9f9f9;
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-item.active {
  background: #e9e9e9;
}

.faq-question {
  font-weight: 600;
  font-size: 1.25rem;
  color: #333;
  position: relative;
}

.faq-toggle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  font-weight: bold;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  position: relative;
  background: white;
  border-top: 1px solid #ddd;
  padding: 0 20px;
}

.faq-answer p {
  margin: 20px 0;
  color: #666;
  line-height: 1.5;
}

.faq-item.active .faq-answer {
  max-height: 1000px;
  /* large enough to fit content */
  padding: 20px;
}

/* Add a plus/minus icon from a font library or use a background-image if preferred */
.faq-toggle::before {
  content: "+";
  color: #C13349;
}

.faq-item.active .faq-toggle::before {
  content: "-";
}

.contact-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.whatsapp-button,
.facebook-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.whatsapp-button {
  background-color: #25D366;
  /* WhatsApp brand color */
}

.whatsapp-button:hover {
  background-color: #128C7E;
  /* Darker shade for hover effect */
}

.facebook-button {
  background-color: #0084FF;
  /* Messenger brand color */
}

.facebook-button:hover {
  background-color: #0058AA;
  /* Darker shade for hover effect */
}

/* Icon styles */
.whatsapp-button svg,
.facebook-button svg {
  margin-right: 8px;
}

.contact-invitation {
  text-align: center;
  margin-top: 40px;
  color: #333;
  /* Dark color for better visibility on white background */
}

.contact-invitation h3 {
  font-size: 2.5rem;
  color: #C13349;
  /* Usa el color principal para destacar el título */
  margin-bottom: 1rem;
  /* Espacio después del título */
  position: relative;
}

.contact-invitation h3::after {
  content: '';
  width: 50px;
  /* Ancho de la línea decorativa */
  height: 4px;
  /* Altura de la línea decorativa */
  background-color: #C13349;
  /* Color de la línea */
  display: block;
  /* Hace que la pseudo-elemento sea como un bloque */
  margin: 10px auto 30px;
  /* Centra la línea y añade espacio antes y después */
}

.contact-invitation p {
  font-size: 1rem;
  margin-bottom: 2rem;
}


@media screen and (max-width: 768px) {
  .faq-section {
    padding: 20px 10px; /* Reduce el padding para aprovechar mejor el espacio */
    margin-bottom: 20px; /* Ajusta el margen inferior para mantener la consistencia */
  }

  .faq-section h2 {
    font-size: 2rem; /* Reduce el tamaño del título para adaptarse a pantallas más pequeñas */
    margin-bottom: 0.5rem; /* Ajusta el espacio después del título */
  }

  .faq-section h2::after {
    margin: 10px auto 20px; /* Ajusta el espaciado de la línea decorativa */
  }

  .faq-item {
    padding: 10px 15px; /* Reduce el padding para que los elementos de la lista ocupen menos espacio */
  }

  .faq-question {
    font-size: .9rem; /* Ajusta el tamaño de la pregunta para mejorar la legibilidad */
  }

  .faq-toggle {
    font-size: 1rem; /* Ajusta el tamaño del icono de alternancia */
  }

  .faq-answer p {
    margin: 15px 0; /* Ajusta el margen para compactar el espacio entre párrafos */
  }

  .contact-buttons {
    flex-direction: column; /* Apila los botones verticalmente para mejor accesibilidad */
    gap: 10px; /* Reduce el espacio entre botones */
  }

  .contact-invitation h3 {
    font-size: 2rem; /* Reduce el tamaño del título para adaptarse a pantallas más pequeñas */
    margin-bottom: 0.5rem; /* Ajusta el espacio después del título */
  }

  .contact-invitation h3::after {
    margin: 10px auto 20px; /* Ajusta el espaciado de la línea decorativa */
  }

  .contact-invitation p {
    font-size: 0.9rem; /* Ajusta el tamaño de la fuente para mejorar la legibilidad */
  }
}

</style>