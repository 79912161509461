<template>
    <section class="about-us" id="about">
      <div class="container">
        <h2>Nosotros</h2>
        <p>
          ¡En Nico's Sorteos Potosinos, somos un grupo potosino que ama repartir
          emoción y oportunidades! Desde que abrimos nuestras puertas en 2022,
          hemos estado en la misión de hacer sonreír a la gente y hacer realidad
          sus sueños con nuestros sorteos súper confiables.
        </p>
        <p>
          Nos hemos expandido mucho desde entonces, añadiendo más premios y
          mejorando la experiencia de todos ustedes, nuestros amigos y
          seguidores. Pero lo que más nos llena de orgullo es la comunidad que
          hemos construido juntos. Nos encanta mantenernos cerca de ustedes y
          compartir la emoción de cada sorteo. Así que únete a la diversión en
          Nico's Sorteos Potosinos, ¡donde la suerte y la alegría nunca faltan!
        </p>
        <div class="team-photos">
          <!-- Imagina que aquí van las fotos del equipo -->
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutUs'
  }
  </script>
  
  <style scoped>
/* nosotros.css */


  
  .about-us {
    color: var(--text-color);
    margin-top: 20px;
    padding: 4rem 2rem; /* Espacio amplio para respirar */
    text-align: center; /* Centra el texto para un diseño limpio */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Fuente moderna y legible */
    margin-bottom: 5rem; /* Espacio debajo de la sección */
    position: relative; /* Posición relativa para alinear los seudoelementos */
  }
  
  .about-us::before,
  .about-us::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 2px;
    background-color: #C13349; /* Color primario definido en las variables */
  }
  
  .about-us::before {
    top: 0;
  }
  
  .about-us::after {
    bottom: 0;
  }
  
  .about-us h2 {
    font-size: 2.5rem; /* Título grande y legible */
    color: #C13349;
    margin-bottom: 1rem; /* Espacio debajo del título */
  }

  .about-us h2::after {
    content: '';
    width: 50px;
    /* Ancho de la línea decorativa */
    height: 4px;
    /* Altura de la línea decorativa */
    background-color: #C13349;
    /* Color de la línea */
    display: block;
    /* Hace que la pseudo-elemento sea como un bloque */
    margin: 10px auto 30px;
    /* Centra la línea y añade espacio antes y después */
  }
  
  
  .about-us p {
    font-size: 1rem; /* Tamaño de fuente cómodo para leer */
    line-height: 1.6; /* Espacio vertical para una lectura más fácil */
    margin-bottom: 1.5rem; /* Espacio debajo de cada párrafo */
  }
  
  .container {
    max-width: 1200px; /* Ancho máximo para el contenido */
    margin: 0 auto; /* Centra el contenedor */
    padding: 0 2rem; /* Padding a los lados para evitar que el texto toque los bordes de la pantalla */
  }
  
  .team-photos {
    display: flex; /* Usa flexbox para alinear las fotos del equipo */
    justify-content: center; /* Centra las fotos del equipo */
    gap: 2rem; /* Espacio entre las fotos del equipo */
    margin-top: 2rem; /* Espacio arriba de las fotos del equipo */
  }
  
  .team-photos div {
    width: 150px; /* Tamaño específico para las fotos */
    height: 150px; /* Tamaño específico para las fotos */
    background-color: #3498db;
    border-radius: 50%; /* Hace que los divs sean círculos */
    /* Agregar estilos para imágenes si es necesario */
  }
  
  @media screen and (max-width: 768px) {
  .about-us {
    padding: 2rem 1rem; /* Reduce el padding para ahorrar espacio */
    margin-bottom: 2.5rem; /* Reduce el margen inferior */
  }
  
  .about-us::before,
  .about-us::after {
    width: 80%; /* Aumenta el ancho de las líneas decorativas para que ocupen más espacio */
  }

  .about-us h2 {
    font-size: 2rem; /* Reduce el tamaño del título para adaptarlo a pantallas más pequeñas */
  }
  
  .about-us h2::after {
    width: 30px; /* Reduce el ancho de la línea decorativa debajo del título */
    margin: 10px auto 20px; /* Ajusta los márgenes para la nueva longitud */
  }
  
  .about-us p {
    font-size: 0.9rem; /* Reduce el tamaño del texto para mejorar la legibilidad */
    margin-bottom: 1rem; /* Reduce el espacio debajo de los párrafos */
  }
  
  .container {
    padding: 0 1rem; /* Reduce el padding lateral */
  }
  
  .team-photos {
    flex-direction: column; /* Cambia la disposición de las fotos a una columna */
    gap: 1rem; /* Reduce el espacio entre las fotos */
  }
  
  .team-photos div {
    width: 120px; /* Reduce el tamaño de las fotos */
    height: 120px; /* Reduce el tamaño de las fotos */
    /* Considera ajustar el tamaño de la imagen de fondo si es necesario */
  }
}

  
  </style>
  