<template>
  <div class="carousel line">
    <div className="product-carousel-section">
      <h2>
        "¡Números ganadores, premios potosinos! Tu suerte está en Nico's Sorteos
        Potosinos."
      </h2>

      <h4>
        "¡Prepárate para ganar en grande con Nico's Sorteos Potosinos! Desde
        motos y consolas hasta televisores y más, tenemos una amplia gama de
        premios esperando por ti. ¡Tu suerte está a punto de cambiar! ¡Ven y
        descubre cómo puedes convertirte en el próximo ganador!"
      </h4>

      <div class="slides product-item">
        <div v-for="(item, index) in carouselItems" :key="index" :class="{ active: index === currentIndex }">
          <img :src="item.image" :alt="item.alt" />
          <h3 class="title">{{ item.title }}</h3>
          <p class="caption">{{ item.caption }}</p>
        </div>
      </div>
      <div class="indicators">
        <span v-for="(item, index) in carouselItems" :key="index" @click="changeSlide(index)"
          :class="{ active: index === currentIndex }">{{ index + 1 }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const carouselItems = ref([
  { image: 'moto.jpg', alt: 'Image 1', title: 'Motocicletas', caption: '¿Listo para sentir la emoción de la carretera? En Nico\'s Sorteos Potosinos, tenemos las mejores motos esperando por ti. Desde elegantes deportivas hasta poderosas cruisers, nuestras motocicletas son tu pasaporte hacia la aventura. ¡No pierdas la oportunidad de acelerar hacia tus sueños! ¡Participa ahora y deja que la suerte telleve al camino de la libertad sobre dos ruedas!' },
  { image: 'consola.png', alt: 'Image 2', title: 'Consolas', caption: ' Sumérgete en la emoción de los videojuegos con las consolas de Nico\'s Sorteos Potosinos. Desde emocionantes aventuras hastacompeticiones frenéticas, nuestras consolas te ofrecen la mejorexperiencia de juego. ¡Participa ahora y deja que la suerte te lleveal siguiente nivel del entretenimiento digital!' },
  { image: 'cel.jpg', alt: 'Image 3', title: 'Celulares', caption: ' ¡Hazte con lo último en tecnología móvil en Nico\'s Sorteos Potosinos! Desde smartphones de alta gama hasta modelos innovadores, nuestras opciones te mantendrán conectado y en movimiento. ¡Participa ya y deja que la suerte te sorprenda con el dispositivo de tus sueños!' },
  { image: 'AirPods.jpg', alt: 'Image 4', title: 'Accesorios', caption: ' Potencia tu experiencia auditiva con los accesorios de Nico\'s Sorteos Potosinos. Desde AirPods hasta potentes bocinas, nuestra selección te ofrece lo mejor en calidad de sonido y comodidad. ¡Sintoniza tu suerte con nosotros y lleva tu música a otro nivel!' }
]);

const currentIndex = ref(0);

function changeSlide(index) {
  currentIndex.value = index;
}
</script>

<style scoped>
.carousel {
  position: relative;
}

.slides {
  display: flex;
  overflow: hidden;
}

.slides>div {
  display: none;
  margin: auto;
}

.slides>.active {
  display: block;
}

h2 {
  font-size: 2.8rem;
  /* Aumenta el tamaño para que el título destaque más */
  color: #C13349;
  /* Elige un color que coincida con el esquema de color de tu marca */

  font-weight: bold;
  /* Hace que el título sea negrita */
  margin-bottom: 1rem;
  /* Espacio debajo del título */
  line-height: 1.2;
  /* Ajusta el interlineado para una mejor legibilidad */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  /* Sombra de texto para dar profundidad */
}

h4 {
  font-size: 1.25rem;
  /* Un tamaño de fuente más moderado */
  color: #333;
  /* Un color oscuro para el texto, pero no demasiado fuerte */
  margin-bottom: 1rem;
  /* Menos espacio debajo del subtítulo */
  font-weight: 300;
  /* Peso de fuente ligero para un look más refinado */
  line-height: 1.4;
  /* Un interlineado ajustado para mantener la simplicidad */

  margin-left: auto;
  /* Centra el bloque de texto horizontalmente */
  margin-right: auto;
}


.carousel-caption {
  font-size: 1.25rem;
  /* Aumenta el tamaño para mejorar la legibilidad */
  max-width: 80%;
  /* Restringe el ancho máximo para un mejor control del texto */
  margin: 0 auto 2rem;
  /* Centra el subtítulo y añade espacio debajo */
  line-height: 1.6;
  /* Aumenta el interlineado para una lectura más cómoda */
  color: #333;
  /* Color oscuro para el texto para facilitar la lectura */
}

.indicators {

  text-align: center;
}

.indicators span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #648540;
  /* Color de fondo para los indicadores inactivos */
  border-radius: 50%;
  /* Hace que los indicadores sean circulares */
  margin: 20px 25px 0px 0px;
  /* Espaciado alrededor de los indicadores */
  cursor: pointer;
  color: transparent;
  /* Hace el texto del número transparente */
  border: 2px solid white;
  /* Añade un borde blanco para que los indicadores destaquen */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /* Sombra suave para dar profundidad */
}

.indicators span.active {
  background-color: #C13349;
  /* Color de fondo para el indicador activo */
  transform: scale(1.1);
  /* Ligeramente más grande para destacar el activo */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  /* Sombra más prominente para el activo */
}


.line {
  margin-top: 20px;
  background-color: hsl(353, 60%, 46%);
  /* Ajusta el margen superior según sea necesario */
}

.line::before {
  content: '';
  display: block;
  width: calc(70% - 100px);
  /* Ajusta el ancho de la línea */
  height: 2px;
  /* Grosor de la línea */
  background-color: var(--primary-color);
  /* Color primario definido en las variables */
  margin: 0 auto 40px;
  /* Margen inferior para separar del carrusel */
}

.product-carousel-section {
  max-width: 90%;
  margin: 1rem auto;
  padding: 2rem;
  text-align: center;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  /* Soft background gradient */
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin-bottom: 20px;
}

.product-item {
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

img {
  display: block;
  /* Elimina espacio extra debajo de la imagen */
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  /* Suaviza los bordes de la imagen */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Añade sombra para darle profundidad */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Transición suave para transform y sombra */
}

.product-item img {
  /* Las propiedades max-width y object-fit permanecen iguales */
  max-height: 250px;
  object-fit: cover;
  /* Cambia a cover para que la imagen llene completamente el área asignada */
  margin: auto;
  margin-top: 5px;
}

.product-item:hover img {
  transform: scale(1.1);
  /* Aumenta el efecto de zoom al pasar el mouse */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  /* Sombra más pronunciada al pasar el mouse para un efecto 3D */
}

/* Considera agregar un contorno o borde cuando la imagen es el foco de atención */
.product-item:focus-within img,
.product-item:active img {
  outline: none;
  /* Elimina el contorno predeterminado */
  box-shadow: 0 0 0 2px #C13349;
  /* Añade un contorno con el color primario para resaltar */
}


.title {
  font-size: 3rem;
  /* Aumenta el tamaño para que el título destaque más */
  font-weight: bold;
  /* Hace que el título sea negrita */
  text-align: center;
  /* Centra el título */
  margin: 2rem 0 1rem;
  /* Añade más espacio encima y debajo del título */
  color: #C13349;
  /* Ajusta el color para que destaque, usa un color que se adapte a tu paleta */
}

/* Slick arrow styles */
.slick-prev,
.slick-next {
  background: #000000;
  /* Fondo blanco para contrastar con el color de la flecha */
  border-radius: 50%;
  /* Redondea el fondo para formar un círculo */
  width: 40px;
  /* Ancho del fondo */
  height: 40px;
  /* Altura del fondo */
  display: flex;
  /* Usa flexbox para centrar la flecha dentro del círculo */
  align-items: center;
  /* Centra verticalmente la flecha */
  justify-content: center;
  /* Centra horizontalmente la flecha */
  /* Sombra suave para resaltar el botón */
}

.slick-prev {
  left: -35px;
  /* Ajusta la posición hacia la izquierda si es necesario */
}

.slick-next {
  right: -35px;
  /* Ajusta la posición hacia la derecha si es necesario */
}

/* Title styles */
.product-carousel-section h2 {
  font-size: 2.5rem;
  color: #C13349;
  /* Usa el color principal para destacar el título */
  margin-bottom: 1rem;
  /* Espacio después del título */
  position: relative;
}

.product-carousel-section h2::after {
  content: '';
  width: 50px;
  /* Ancho de la línea decorativa */
  height: 4px;
  /* Altura de la línea decorativa */
  background-color: #C13349;
  /* Color de la línea */
  display: block;
  /* Hace que la pseudo-elemento sea como un bloque */
  margin: 10px auto 30px;
  /* Centra la línea y añade espacio antes y después */
}

.carousel-button-container {
  text-align: center;
}

.section-container {
  background: #f4f4f4;
  /* Color de fondo suave para la sección */
  padding: 3rem;
  /* Espaciado interior generoso */
  border-radius: 10px;
  /* Esquinas redondeadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra sutil para dar profundidad */
}


.view-raffles-button {
  padding: 10px 20px;
  background-color: #C13349;
  /* Primary color */
  color: #fff;
  /* Text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Shadow for depth */
}

.view-raffles-button:hover {
  background-color: #711924;
  /* Darker shade for hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Larger shadow on hover */
}

.view-raffles-button:focus {
  outline: none;
}

@media screen and (max-width: 768px) {

  /* Ajustes para el carrusel */
  .carousel-caption {
    font-size: 1rem;
    /* Tamaño de fuente más pequeño para el subtítulo */
    padding: 10px;
    /* Menos padding para maximizar el espacio */
  }

  /* Ajustes para títulos y subtítulos */
  h2 {
    font-size: 2rem;
    /* Tamaño de fuente más pequeño para el título */
    margin-bottom: 0.5rem;
    /* Menos margen inferior para el título */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    /* Sombra de texto más sutil */
  }

  h4 {
    font-size: 1rem;
    /* Tamaño de fuente más pequeño para el subtítulo */
    margin-bottom: 0.5rem;
    /* Menos margen inferior para el subtítulo */
    line-height: 1.2;
    /* Interlineado ajustado para el subtítulo */
  }

  /* Ajustes para los indicadores */
  .indicators span {
    width: 15px;
    /* Indicadores más pequeños */
    height: 15px;
    /* Indicadores más pequeños */
    margin: 10px;
    /* Menos espacio entre los indicadores */
  }

  /* Ajustes para el botón de vista del carrusel */
  .view-raffles-button {
    padding: 8px 16px;
    /* Padding más pequeño para el botón */
    font-size: 0.9rem;
    /* Tamaño de fuente más pequeño para el botón */
  }

  /* Ajustes para las imágenes del producto */
  .product-item img {
    max-height: 200px;
    /* Altura máxima más pequeña para las imágenes */
  }

  /* Ajustes para los botones de navegación del carrusel */
  .slick-prev,
  .slick-next {
    width: 30px;
    /* Botones más pequeños */
    height: 30px;
    /* Botones más pequeños */
    left: 10px;
    /* Ajusta la posición hacia la izquierda para .slick-prev */
    right: 10px;
    /* Ajusta la posición hacia la derecha para .slick-next */
  }
}
</style>