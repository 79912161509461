<template>
    <footer class="site-footer" id="contacto">
        <div class="footer-content">
            <!-- Información de Contacto -->
            <div class="footer-section">
                <h4>Información de Contacto</h4>
                <ul class="contact-list">
                    <li>
                        <strong>Correo:</strong>
                        <a href="mailto:nicossorteos@gmail.com"> nicossorteos@gmail.com</a>
                    </li>
                    <li>
                        <strong>Teléfono:</strong>
                        <a href="tel:+524445454450"> (444) 545 4450</a>
                    </li>
                </ul>
            </div>

            <!-- Síguenos -->
            <div class="footer-section">
                <h4>Síguenos</h4>
                <div class="social-links">
                    <a href="https://www.facebook.com/profile.php?id=61555499965336" aria-label="Facebook">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://wa.me/4445454450" aria-label="Whatsapp">
                        <i class="fab fa-whatsapp"></i>
                    </a>
                </div>
            </div>
        </div>

        <!-- Créditos -->
        <p class="footer-bottom">
            Creado por &nbsp;
            <a href="https://softdone.com.mx" target="_blank" rel="noopener noreferrer" class="footer-link">
                Softdone
            </a>
            <span class="footer-year">&nbsp;&copy; {{ new Date().getFullYear() }}</span> &nbsp;Todos los derechos
            reservados.
        </p>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
};
</script>

<style scoped>
.site-footer {
    background-color: #333;
    color: white;
    padding: 40px 0;
    font-family: 'Arial', sans-serif;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0px;
}

.footer-section {
    margin: 20px;
}

.footer-section h4 {
    color: #ddd;
    margin-bottom: 15px;
}

.contact-list,
.social-links {
    list-style-type: none;
    padding: 0;
}

.contact-list li {
    margin-bottom: 8px;
}

.contact-list a {
    text-decoration: none;
    /* Elimina el subrayado de los enlaces */
    color: #3498db;
    /* Color del enlace */
    transition: color 0.3s ease;
    /* Efecto suave al cambiar de color */
}

.contact-list a:hover,
.contact-list a:focus {
    color: #2980b9;
    /* Color del enlace al pasar el ratón o al enfocar */
}


.social-links a {
    color: white;
    text-decoration: none;
    font-size: 24px;
    /* Make the icons larger */
    margin: 0 10px;
    /* Add space around icons */
    display: inline-block;
    /* Aligns icons better with the text */
    transition: color 0.3s ease;
    /* Smooth color transition for hover state */
}

.social-links a:hover {
    color: #007BFF;
    /* Change color when hovering over the icons */
}

.footer-bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #444;
    padding: 20px 0;
}

.footer-bottom {
    text-align: center;
    margin: 0;
    font-size: 0.9rem;
}

.footer-link {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #0056b3;
}

/* Media Queries para dispositivos móviles */
@media screen and (max-width: 768px) {
  .site-footer {
    padding: 20px 10px; /* Reduce el padding para dispositivos móviles */
  }

  .footer-content {
    justify-content: center; /* Centra el contenido en dispositivos móviles */
    padding: 0 20px; /* Añade padding en los lados para evitar que el contenido toque los bordes */
  }

  .footer-section {
    margin: 10px 0; /* Reduce el margen para cada sección para ahorrar espacio */
    flex-basis: 100%; /* Hace que las secciones ocupen todo el ancho */
    text-align: center; /* Centra el texto de cada sección */
  }

  .social-links a {
    margin: 0 5px; /* Reduce el espacio alrededor de los íconos para dispositivos más pequeños */
    font-size: 20px; /* Reduce el tamaño de los íconos para que encajen mejor */
  }

  .footer-bottom-container {
    flex-direction: column; /* Cambia la dirección a columna para apilar elementos verticalmente */
    padding: 10px 0; /* Reduce el padding */
  }

  .footer-bottom {
    font-size: 0.8rem; /* Reduce el tamaño de la fuente para el texto en la parte inferior */
  }
}


</style>