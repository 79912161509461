<template>
  <nav class="navbar">
    <div class="navbar-logo">
      <router-link to="/">
        <img src="/logositio.png" alt="Logo">
      </router-link>
      <span class="navbar-brand">Nico's Sorteos Potosinos</span>
    </div>
    <button class="menu-toggle" @click="toggleNav">
      &#9776;
    </button>
    
    <div class="navbar-links" :class="{ 'active': isNavExpanded }">
      <router-link to="/" @click="closeNav">Inicio</router-link>
      <router-link to="/sorteos" @click="closeNav">Sorteos</router-link>
      <router-link to="/ganadores" @click="closeNav">Ganadores</router-link>
    </div>

  </nav>
  <router-view />
</template>


<script>
import { ref } from 'vue';

export default {
  name: 'Menu',
  setup() {
    const isNavExpanded = ref(false);

    function toggleNav() {
      isNavExpanded.value = !isNavExpanded.value;
    }

    function closeNav() {
      isNavExpanded.value = false;
    }

    return { isNavExpanded, toggleNav, closeNav };
  }
}
</script>


<style scoped>
.navbar {
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: hsl(353, 60%, 46%);
  /* Utiliza los colores primarios para el gradiente */
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  /* Sombra sutil para dar un efecto elevado */
  color: var(--whiteColor);
  /* Texto en blanco para contraste */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  /* Sombra de texto para mejorar el contraste */
  z-index: 1000;
}

.navbar-logo {
  display: flex;
  align-items: center;
}


.navbar-logo img {
  height: 100px;
  width: auto;
  transition: height 0.2s ease-in-out;
}

.navbar-brand {
  font-family: 'Roboto', sans-serif;
  font-size: 2.5rem;
  /* Ajusta el tamaño de la fuente según sea necesario */
  color: #ecf0f1;
  /* El color del texto */
  margin-left: 1rem;
  /* Espacio entre el logo y el texto */
}


.menu-toggle {
  display: none;
  cursor: pointer;
  font-size: 2rem;
}

.navbar-links a {
  text-decoration: none;
  color: #ecf0f1;
  padding: 0 1rem;
  font-family: "Montserrat", sans-serif;
  position: relative;
  /* Posición relativa para poder posicionar la línea absolutamente */
  font-size: 1.25rem;
  /* Tamaño de fuente aumentado para los enlaces */
  transition: color 0.3s ease-in-out;
  z-index: 1001;
}

.navbar-links a::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px;
  /* Ajusta la posición de la línea debajo del texto */
  height: 2px;
  /* Altura de la línea */
  width: 0;
  /* La línea estará inicialmente oculta */
  background-color: #C13349;
  /* Color de la línea */
  transition: width 0.3s ease-in-out;
  /* Transición suave para la aparición de la línea */
  z-index: 1002;
}

.navbar-links a:hover::after,
.navbar-links a.active::after {
  width: 100%;
  /* La línea se expande al 100% del ancho del texto al pasar el ratón */
}

/* Media Queries para dispositivos móviles con ajuste para un logo más grande */
@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    /* Asegura que los elementos estén centrados */
    padding: 1rem 0;
    /* Ajusta el padding superior e inferior para más espacio alrededor del logo */
  }

  .navbar-logo,
  .navbar-brand {
    justify-content: center;
    /* Centra el logo y la marca en dispositivos móviles */
    display: flex;
    /* Usa flexbox para centrar el contenido */
    width: 100%;
    /* Asegura que el logo y la marca ocupen todo el ancho */
  }

  .navbar-logo img {
    margin: 0 auto;
    /* Centra el logo horizontalmente */
    height: 80px;
    /* Aumenta el tamaño del logo para hacerlo más destacado en dispositivos móviles */
  }

  .navbar-brand {
    display: none;
    /* Continúa ocultando el texto de la marca para mostrar solo el logo */
  }

  .navbar-links.active {
  display: flex; /* Cambia a flex para mostrar los enlaces */
  flex-direction: column; /* Los enlaces se muestran en columna */
  align-items: center; /* Centra los enlaces */
  width: 100%; /* Ocupa todo el ancho disponible */
  background-color: hsl(353, 60%, 46%); /* Fondo blanco con ligera transparencia */
}

  /* Ajuste para el botón de menú toggle con fondo blanco y transparente */
  .menu-toggle {
    display: block; /* Asegura que el botón de menú esté visible */
    /* Muestra el botón de menú toggle */
    position: absolute;
    right: 20px;
    /* Posiciona el botón a la derecha */
    top: 20px;
    /* Asegura que el botón esté en la parte superior */
    background-color: rgba(255, 255, 255, 0.5);
    /* Establece el fondo blanco y semitransparente */
    border: none;
    /* Elimina el borde por defecto */
    padding: 0.5rem;
    /* Añade un poco de padding para mayor área de clic */
    border-radius: 5px;
    /* Suaviza las esquinas para un aspecto más moderno */
    cursor: pointer;
    /* Cambia el cursor a pointer para indicar que es clickeable */
  }

  /* Asegúrate de tener un ícono o algo visual dentro de .menu-toggle para que los usuarios entiendan su función */
  .navbar-links {
    display: none;
    /* Mantiene ocultos inicialmente los enlaces del menú */
  }
}


/* Añade JavaScript para manejar el clic del botón menu-toggle
   y alternar la visualización de .navbar-links */
</style>
