import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sorteos",
    name: "sorteos",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SorteosInicio.vue"),
  },
  {
    path: "/ganadores",
    name: "ganadores",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Ganadores.vue"),
  },
  {
    path: "/detalle/:id",
    name: "sorteoDetalle",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Detalle.vue"),
    props: true, // Habilita la pasarela de props para enviar el parámetro ID al componente
  },
  {
    path: "/login-admin-panel",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/sorteos-lista",
    name: "sorteosLista",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Sorteos-Lista.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/boletos-apartados",
    name: "boletoApartado",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Boletos-Apartados.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/boletoapartado/:id",
    name: "boletoApartadodetalle",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Apartado-Detalle.vue"),
    props: true, // Habilita la pasarela de props para enviar el parámetro ID al componente
  },
  {
    path: "/boletos-vendidos",
    name: "boletoVendido",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Boletos-Vendidos.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/boletovendido/:id",
    name: "boletoVendidodetalle",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Vendido-Detalle.vue"),
    props: true, // Habilita la pasarela de props para enviar el parámetro ID al componente
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("authToken");
  // Verifica si la ruta requiere autenticación
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Verifica si el usuario está autenticado comprobando si existe el token en el almacenamiento local
    const isAuthenticated = localStorage.getItem("authToken");

    if (!isAuthenticated) {
      // Si el usuario no está autenticado, redirige a la página de inicio de sesión
      next({ name: "login" });
    } else {
      // Si el usuario está autenticado, permite el acceso a la ruta
      next();
    }
  } else {
    // Si la ruta no requiere autenticación, permite el acceso
    next();
  }
});

export default router;
